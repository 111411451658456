import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../components/Layout';
import { TalesList } from '../components/TalesList';
import { ImagesTalePreviewData, TextTalePreviewData } from '../types';
import { getFieldsForTalePreview } from '../helpers/getFieldsForTalePreview';

interface AuthorPageTemplateData {
    authors: {
        _id: string;
        name: string;
        slug: string;
    }
    allTextTales: {
        nodes: TextTalePreviewData[];
    }
    allImagesTales: {
        nodes: ImagesTalePreviewData[];
    }
}

export const AuthorPageTemplate: FC<PageProps<AuthorPageTemplateData>> = ({data, location}) => {
    const { authors: {name}, allTextTales, allImagesTales } = data;

    const textTales = allTextTales.nodes.map(getFieldsForTalePreview('text'));
    const imagesTales = allImagesTales.nodes.map(getFieldsForTalePreview('images'));

    return (
        <Layout seoTitle={name} location={location}>
            <TalesList list={[...textTales, ...imagesTales]} title={name} />
        </Layout>
    );
}

export const query = graphql`
  query($id: Int!) {
      authors(_id: {eq: $id}) {
        _id
        name
        slug
      }
      allTextTales(filter: {author_id: {eq: $id}}) {
        nodes {
          _id
          slug
          name
          description
          timeToRead
          author_id
        }
      }
      allImagesTales(filter: {author_id: {eq: $id}}) {
        nodes {
          _id
          slug
          name
          description
          timeToRead
          author_id
        }
      }
  }
`
export default AuthorPageTemplate;
